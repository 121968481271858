import { UploaderInput } from "./uploader_input";

export class Controller {
  constructor() {
    this._uploaderInputs = new Map();
    this._uploaderSelector = '.dropzone';
  }

  start () {
    this.init()
    this.attachEventListeners()
  }

  init() {
    this.cleanup();
    this.createUploadInputs();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => this.init());
  }

  cleanup() {
    this._uploaderInputs.forEach((uploaderInput, key) => {
      if (!uploaderInput.isAttached()) {
        this._uploaderInputs.delete(key);
        uploaderInput.destroy();
      }
    }, this)
  }

  createUploadInputs() {
    const elements = document.querySelectorAll(this._uploaderSelector);

    elements.forEach((element) => {
      const key = element.attributes['data-container-id'].value;
      if(!this._uploaderInputs.has(key)){
        const uploaderInput =  new UploaderInput(element)
        uploaderInput.create();
        this._uploaderInputs.set(key, uploaderInput);
      }
    }, this );
  }
}
